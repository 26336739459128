import { useQueryClient } from '@tanstack/react-query';

import { GiftCardsDialogSuccessStates, useGiftCardsStore } from '@/store';

import { giftCardsQueryKeys } from '../gift-cards-query-keys';

export function useDeleteGiftCardSuccess() {
  const queryClient = useQueryClient();
  const {
    setIsDeleteGiftCardPending,
    setIsOpenDeleteDialog,
    setIsViewGiftCardOpen,
    setSelectedGiftCard,
  } = useGiftCardsStore().use.actions();
  const setGiftCardsDialogSuccessState =
    useGiftCardsStore().use.actions().setGiftCardsDialogSuccessState;

  return function useAddGiftCardSuccessCallback() {
    queryClient.invalidateQueries({
      queryKey: giftCardsQueryKeys.allCardsGiftcards,
    });
    setGiftCardsDialogSuccessState(
      GiftCardsDialogSuccessStates.GIFT_CARD_DELETE
    );
    setSelectedGiftCard(undefined);
    setIsDeleteGiftCardPending(false);
    setIsOpenDeleteDialog(false);
    setIsViewGiftCardOpen(false);
  };
}
