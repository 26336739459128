import { ButtonSpinner, Divider, SecurityAssurance } from '@/components';
import { useBankCardsStore } from '@/store';

import styles from './add-card-form-footer.module.css';

export function AddCardFormFooter() {
  const is3dsIframeOpen = useBankCardsStore().use.is3dsIframeOpen();
  const isAddBankCardPending = useBankCardsStore().use.isAddBankCardPending();

  return (
    <div className={styles['add-card-form-footer']}>
      {!is3dsIframeOpen && <SecurityAssurance />}

      <Divider />

      <ButtonSpinner
        disabled={isAddBankCardPending}
        level="primary"
        loading={isAddBankCardPending}
        size="medium"
        spinnerColor={'var(--colors-onSurfaceC)'}
        type="submit"
        variant="branded"
      >
        Save
      </ButtonSpinner>
    </div>
  );
}
