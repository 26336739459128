/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { hasAuthParams, useAuth } from 'react-oidc-context';

import { LoadingScreen } from '@/components';
import { idleConfig } from '@/config';
import { useAnalytics } from '@/hooks/use-analytics';

import { Routes } from './constants';

declare global {
  interface Window {
    ReactNativeWebView: any;
    appInterface: any;
    webkit: any;
  }
}

interface IProps {
  children: React.ReactNode;
}

export const ProtectedRoute = ({ children }: IProps) => {
  const auth = useAuth();
  const { track } = useAnalytics();

  const handleIdle = () => {
    if (auth.isAuthenticated) {
      auth.removeUser().then(() => {
        window.location.replace(Routes.TIMED_OUT);
      });
    }
  };

  const { isIdle } = useIdleTimer({
    ...idleConfig,
    onIdle: handleIdle,
  });

  useEffect(() => {
    const redirectToPing = async (): Promise<void> => {
      await new Promise<void>((resolve) => {
        track('Initialise Wallet');
        resolve();
      });
      auth.signinRedirect();
    };
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !isIdle()
    ) {
      redirectToPing();
    }
  }, [auth, isIdle, track]);

  if (auth.isLoading || auth.activeNavigator) {
    return <LoadingScreen />;
  }

  return children;
};
