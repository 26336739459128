export enum GiftCardsDialogSuccessStates {
  GIFT_CARD_ADD = 'GIFT_CARD_ADD',
  GIFT_CARD_DELETE = 'GIFT_CARD_DELETE',
  NULL = 'NULL',
}

export enum GiftCardsDialogErrorStates {
  GIFT_CARD_ALREADY_ADDED = 'GIFT_CARD_ALREADY_ADDED',
  GIFT_CARD_CANCELLED = 'GIFT_CARD_CANCELLED',
  GIFT_CARD_COUNT_LIMIT_REACHED = 'GIFT_CARD_COUNT_LIMIT_REACHED',
  GIFT_CARD_DELETE_ERROR = 'GIFT_CARD_DELETE_ERROR',
  GIFT_CARD_EXPIRED = 'GIFT_CARD_EXPIRED',
  GIFT_CARD_INSUFFICIENT_BALANCE = 'GIFT_CARD_INSUFFICIENT_BALANCE',
  GIFT_CARD_NOT_ACTIVATED_OR_ISSUED = 'GIFT_CARD_NOT_ACTIVATED_OR_ISSUED',
  GIFT_CARD_NOT_SUPPORTED = 'GIFT_CARD_NOT_SUPPORTED',
  GIFT_CARD_SUSPENDED = 'GIFT_CARD_SUSPENDED',
  GIFT_CARD_UNKNOWN_ERROR = 'GIFT_CARD_UNKNOWN_ERROR',
  NULL = 'NULL',
}
