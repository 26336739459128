import { useGiftCardsStore } from '@/store';

import { useAddGiftCardDialogSuccess } from './use-add-gift-card-dialog-success';

import type { GiftCardTypes } from '../../types/gift-card-types';

export function useGiftCardsEvents() {
  useAddGiftCardDialogSuccess();

  const isViewGiftCardOpen = useGiftCardsStore().use.isViewGiftCardOpen();

  const {
    setIsAddGiftCardOpen,
    setIsAddGiftCardPending,
    setIsDeleteGiftCardPending,
    setIsViewGiftCardOpen,
    setSelectedGiftCard,
  } = useGiftCardsStore().use.actions();

  return {
    handleAddCardClose: () => {
      setIsAddGiftCardOpen(false);
      setIsAddGiftCardPending(false);
    },

    handleAddCardOpen: () => {
      setIsAddGiftCardOpen(true);
    },

    handleSetSelectedGiftCard:
      (card: GiftCardTypes.Components.TGiftCard) => () => {
        setSelectedGiftCard(card);

        if (!isViewGiftCardOpen) {
          setIsViewGiftCardOpen(true);
        }
      },

    handleViewGiftCardClose: () => {
      setIsViewGiftCardOpen(false);
      setSelectedGiftCard();
      setIsDeleteGiftCardPending(false);
    },
  };
}
