import { Layout, Panel, Title } from '@/components';
import { BankCards } from '@/features/bank-cards';
import { GiftCards } from '@/features/gift-cards';
import { usePageTitle } from '@/hooks/use-page-title';

import { LoyaltyCardPanel } from './loyalty-card';
import styles from './wallet.module.css';

export const Wallet = () => {
  usePageTitle('Wallet');

  const hideInSandboxProduction =
    !import.meta.env.MODE.includes('sandbox') &&
    !import.meta.env.MODE.includes('production');

  return (
    <Layout>
      <Title title="Your wallet" />

      <div className={styles['wallet-container']}>
        <div className={styles['wallet-column']}>
          <BankCards />
        </div>

        <div className={styles['wallet-column']}>
          <Panel title="Memberships">
            <LoyaltyCardPanel />
          </Panel>
        </div>

        {hideInSandboxProduction && (
          <div className={styles['wallet-column']}>
            <GiftCards />
          </div>
        )}
      </div>
    </Layout>
  );
};
