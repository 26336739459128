import {
  BankCardsDialogErrorStates,
  BankCardsDialogSuccessStates,
} from './bank-cards-store-enums';

import type { IBankCardStoreStateValues } from './bank-cards-store-types';

export const bankCardsStoreDefaultValues: IBankCardStoreStateValues = {
  bankCardsDialogErrorState: BankCardsDialogErrorStates.NULL,
  bankCardsDialogSuccessState: BankCardsDialogSuccessStates.NULL,
  finalizePayload: undefined,
  is3dsIframeOpen: false,
  isAddBankCardOpen: false,
  isAddBankCardPending: false,
  isBankCardLimitReachedOpen: false,
  isEditingCard: false,
  isEditingCardNickname: false,
  isOpenBankCardsList: false,
  isOpenDeleteDialog: false,
  isViewBankCardDetailsOpen: false,
  selectedBankCard: undefined,
  threeDSecureId: '',
};
