import { Styled } from './styles';

export const LoadingCard = ({ loadingLabel }: { loadingLabel?: string }) => {
  return (
    <Styled.LoadingCard aria-label={loadingLabel} role="progressbar">
      <Styled.CardWrapper>
        <Styled.CardLoader />
      </Styled.CardWrapper>

      <Styled.TextWrapper>
        <Styled.TextLoaderLong />
        <Styled.TextLoaderShort />
        <Styled.TextLoaderShort />
      </Styled.TextWrapper>
    </Styled.LoadingCard>
  );
};
