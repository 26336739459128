import { useMutation } from '@tanstack/react-query';

import { paymentMethodQueryKeys } from '../query-keys';

import { useUpdateCardRequest } from './use-update-card-request';

import type { TBaasAddPaymentMethodErrors } from '../../types';
import type { BankCardsTypes } from '../../types/bank-cards-types';
import type { TBaasErrorResponse } from '@/services';
import type { UseMutationOptions } from '@tanstack/react-query';

export function useUpdateCard(
  cardId: string,
  options?: UseMutationOptions<
    BankCardsTypes.Operations.UpdateBankCard.TResponse,
    TBaasErrorResponse<TBaasAddPaymentMethodErrors>,
    BankCardsTypes.Operations.UpdateBankCard.TRequest,
    unknown
  >
) {
  return useMutation({
    mutationFn: useUpdateCardRequest(cardId),
    mutationKey: paymentMethodQueryKeys.cardsBankUpdate,
    ...options,
  });
}
