import type { TActivityStoreSet } from './activity-store-types';
import type { TFilters } from '@/features/activity';

export function activityStoreActions(set: TActivityStoreSet) {
  return {
    setActiveFilters: (activeFilters?: TFilters) => set({ activeFilters }),
    setIsFiltersSideSheetOpen: (isFiltersSideSheetOpen: boolean) =>
      set({ isFiltersSideSheetOpen }),
    setIsViewFilterCalendar: (isViewFilterCalendar: boolean) =>
      set({ isViewFilterCalendar }),
  };
}
