import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  ButtonSpinner,
  Divider,
  ErrorDialog,
  Form,
  SideSheet,
  SuccessDialog,
  TextField,
} from '@/components';
import { genericContent } from '@/config/language';
import {
  ERROR_INITIATE_MOBILE_CONFLICT,
  ERROR_VERIFY_MOBILE,
} from '@/config/language/errors';
import {
  useMobileCancelChange,
  useMobileInitiateChange,
  useMobileResendChange,
  useMobileVerify,
} from '@/features/profile';
import { useAnalytics } from '@/hooks';
import { APIErrors } from '@/services/errors';
import { cleanMobileNumber, validate } from '@/utils';

import { Styled } from './styles';

import type { TBaasError } from '@/services';
import type { FieldValues } from 'react-hook-form';

export enum FormState {
  MOBILE = 'mobile',
  VERIFICATION_CODE = 'verification_code',
}

const DEFAULT_MOBILE_FORM_VALUES = {
  mobile_number: '',
} as FieldValues;

const DEFAULT_VERIFICATION_FORM_VALUES = {
  verification_code: '',
} as FieldValues;

const mobileNumberValidationSchema = Joi.object({
  mobile_number: validate.phoneNumber(),
}).unknown(true);

const verificationCodeValidationSchema = Joi.object({
  verification_code: validate.verificationCodePhoneNumber(),
}).unknown(true);

type TUpdateMobileSheet = {
  onClose: () => void;
  open: boolean;
  reload: () => void;
};

export const UpdateMobileSheet = ({
  onClose,
  open,
  reload,
}: TUpdateMobileSheet) => {
  const { track } = useAnalytics();

  const codeRef = useRef<HTMLInputElement>(null);
  const [updateState, setUpdateState] = useState(FormState.MOBILE);
  const [isOpenSuccess, setIsOpenSuccess] = useState(false);
  const [mobile, setMobile] = useState('');
  const [isOpenError, setIsOpenError] = useState(false);

  const formMethods = useForm({
    defaultValues:
      updateState === FormState.MOBILE
        ? DEFAULT_MOBILE_FORM_VALUES
        : DEFAULT_VERIFICATION_FORM_VALUES,
    reValidateMode: 'onChange',
    resolver: joiResolver(
      updateState === FormState.MOBILE
        ? mobileNumberValidationSchema
        : verificationCodeValidationSchema
    ),
  });

  const onInitiateError = (error: TBaasError) => {
    const statusCode = error?.response?.status;
    if (statusCode === APIErrors.CONFLICT) {
      formMethods.setError('mobile_number', {
        message: ERROR_INITIATE_MOBILE_CONFLICT,
        type: 'manual',
      });
    } else {
      setIsOpenError(true);
    }
    setTimeout(() => {
      codeRef.current?.focus();
    }, 100);
  };

  const onInitiateSuccess = () => {
    formMethods.reset();
    setUpdateState(FormState.VERIFICATION_CODE);
    setTimeout(() => {
      codeRef.current?.focus();
    }, 100);
  };

  const mobileInitiateChange = useMobileInitiateChange({
    onError: onInitiateError,
    onSuccess: onInitiateSuccess,
  });

  const onVerifyError = (error: TBaasError) => {
    track('Mobile Validation Failed');
    const statusCode = error?.response?.status;
    if (statusCode === APIErrors.BAD_REQUEST) {
      formMethods.setError('verification_code', {
        message: 'Invalid verification code',
        type: 'manual',
      });
    } else {
      setIsOpenError(true);
    }
  };

  const onVerifySuccess = () => {
    track('Update Mobile Confirmed');
    setIsOpenSuccess(true);
    formMethods.reset();
    setUpdateState(FormState.MOBILE);
  };

  const mobileVerify = useMobileVerify({
    onError: onVerifyError,
    onSuccess: onVerifySuccess,
  });

  const mobileCancelChange = useMobileCancelChange();

  const mobileResendChange = useMobileResendChange();

  const onSubmitForm = async ({
    mobile_number,
    verification_code,
  }: FieldValues) => {
    updateState === FormState.MOBILE
      ? mobileInitiateChange.mutate({
          mobile_number: cleanMobileNumber(mobile_number),
        })
      : mobileVerify.mutate({
          mobile_number: cleanMobileNumber(mobile),
          verification_code,
        });
  };

  const onCloseSuccessDialog = () => {
    reload();
    setIsOpenSuccess(false);
    formMethods.reset();
  };

  const onCloseErrorDialog = () => {
    setIsOpenError(false);
  };

  const onCloseSideSheet = () => {
    onClose();
    formMethods.reset();
    if (updateState === FormState.VERIFICATION_CODE) {
      mobileCancelChange.mutate({
        mobile_number: cleanMobileNumber(mobile),
      });
      setUpdateState(FormState.MOBILE);
    }
  };

  const onClickResend = () => {
    mobileResendChange.mutate({
      mobile_number: cleanMobileNumber(mobile),
    });
  };

  const handleChangeMobile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (updateState === FormState.MOBILE) {
      setMobile(e.target.value);
    }
  };

  const isSubmitting = mobileInitiateChange.isPending || mobileVerify.isPending;

  return (
    <>
      <SideSheet
        onClose={onCloseSideSheet}
        open={open}
        subtitle={
          updateState === FormState.MOBILE
            ? "Enter your new mobile number. We'll send you a confirmation code to verify the update."
            : 'Enter the confirmation code to verify your new mobile number.'
        }
        title={
          updateState === FormState.MOBILE
            ? 'Update your mobile number'
            : 'Verify new mobile number'
        }
      >
        <Styled.UpdateContentWrapper>
          <Form config={formMethods} onSubmitForm={onSubmitForm}>
            <Styled.Fieldset>
              <TextField
                disabled={!!isSubmitting}
                label={
                  updateState === FormState.MOBILE
                    ? 'New mobile number'
                    : 'Verification code'
                }
                name={
                  updateState === FormState.MOBILE
                    ? 'mobile_number'
                    : 'verification_code'
                }
                onChange={handleChangeMobile}
                placeholder={updateState === FormState.MOBILE ? '' : '•••••••'}
                ref={codeRef}
              />
            </Styled.Fieldset>
            <Styled.Footer>
              <Divider />
              <ButtonSpinner
                disabled={isSubmitting}
                level="primary"
                loading={isSubmitting}
                size="medium"
                spinnerColor={'var(--colors-onSurfaceC)'}
                type="submit"
                variant="branded"
              >
                {isSubmitting ? 'Loading' : 'Continue'}
              </ButtonSpinner>
              {updateState === FormState.VERIFICATION_CODE && (
                <ButtonSpinner
                  disabled={mobileResendChange.isPending}
                  level="secondary"
                  loading={mobileResendChange.isPending}
                  onClick={onClickResend}
                  size="medium"
                  spinnerColor={'var(--colors-onSurfaceC)'}
                  type="button"
                  variant="neutral"
                >
                  {mobileResendChange.isPending ? 'Loading' : 'Resend code'}
                </ButtonSpinner>
              )}
            </Styled.Footer>
          </Form>
        </Styled.UpdateContentWrapper>
        {isOpenError && (
          <ErrorDialog
            isOpen={isOpenError}
            message={ERROR_VERIFY_MOBILE}
            onOpenChange={onCloseErrorDialog}
            title={genericContent.ERROR_GENERIC_TITLE}
          />
        )}
        {isOpenSuccess && (
          <SuccessDialog
            buttonText="Back to my account"
            description={"You've successfully updated your mobile number"}
            isOpen={isOpenSuccess}
            onOpenChange={onCloseSuccessDialog}
            title={'Mobile number updated'}
          />
        )}
      </SideSheet>
    </>
  );
};
