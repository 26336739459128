import type {
  TBankCardsDialogErrorState,
  TBankCardsDialogSuccessState,
  TBankCardsStoreSet,
} from './bank-cards-store-types';
import type { BankCardsTypes } from '@/features/bank-cards/types/bank-cards-types';

export const bankCardsStoreActions = (set: TBankCardsStoreSet) => ({
  setBankCardsDialogErrorState: (
    bankCardsDialogErrorState: TBankCardsDialogErrorState
  ) => set({ bankCardsDialogErrorState }),
  setBankCardsDialogSuccessState: (
    bankCardsDialogSuccessState: TBankCardsDialogSuccessState
  ) => set({ bankCardsDialogSuccessState }),
  setFinalizePayload: (
    finalizePayload:
      | BankCardsTypes.Operations.FinalizeBankCard.TRequest
      | undefined
  ) => set({ finalizePayload }),
  setIs3dsIframeOpen: (is3dsIframeOpen: boolean) => set({ is3dsIframeOpen }),
  setIsAddBankCardOpen: (isAddBankCardOpen: boolean) =>
    set({ isAddBankCardOpen }),
  setIsAddBankCardPending: (isAddBankCardPending: boolean) =>
    set({ isAddBankCardPending }),
  setIsBankCardLimitReachedOpen: (isBankCardLimitReachedOpen: boolean) =>
    set({ isBankCardLimitReachedOpen }),
  setIsEditingCard: (isEditingCard: boolean) => set({ isEditingCard }),
  setIsEditingCardNickname: (isEditingCardNickname: boolean) =>
    set({ isEditingCardNickname }),
  setIsOpenBankCardsList: (isOpenBankCardsList: boolean) =>
    set({ isOpenBankCardsList }),
  setIsOpenDeleteDialog: (isOpenDeleteDialog: boolean) =>
    set({ isOpenDeleteDialog }),
  setIsViewBankCardDetailsOpen: (isViewBankCardDetailsOpen: boolean) =>
    set({ isViewBankCardDetailsOpen }),
  setSelectedBankCard: (
    selectedBankCard: BankCardsTypes.Components.TBankCard | undefined
  ) => set({ selectedBankCard }),
  setThreeDSecureId: (threeDSecureId: string) => set({ threeDSecureId }),
});
