import { endOfDay, formatISO, parse, startOfDay } from 'date-fns';

import { DateFormats } from '@/config/language';

import type { TResponseFilters } from '../types';
import type { TFilters } from '@/features/activity';

function parseFromDate(date: string) {
  const parsedDate = parse(date, DateFormats.DAY_MONTH_YEAR, new Date());
  return startOfDay(parsedDate);
}

function parseToDate(date: string) {
  const parsedDate = parse(date, DateFormats.DAY_MONTH_YEAR, new Date());
  return endOfDay(parsedDate);
}

export function formatActiveFilters(
  filters: TFilters | undefined
): TResponseFilters {
  return !!filters
    ? {
        from_date: filters.dateFrom
          ? formatISO(parseFromDate(filters.dateFrom))
          : undefined,
        max_amount: filters.maxAmount
          ? Number.parseFloat(filters.maxAmount) * 100
          : undefined,
        min_amount: filters.minAmount
          ? Number.parseFloat(filters.minAmount) * 100
          : undefined,
        payment_context: filters.type,
        to_date: filters.dateTo
          ? formatISO(parseToDate(filters.dateTo))
          : undefined,
      }
    : {};
}
