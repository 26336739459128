import { Dialog } from '@/components';
import { useGiftCardsStore } from '@/store';

import { EligibleGiftCardList } from '../eligible-gift-card-list/eligible-gift-card-list';

import styles from './eligible-gift-cards.module.css';

export function EligibleGiftCards() {
  const isViewEligibleGiftCardsOpen =
    useGiftCardsStore().use.isViewEligibleGiftCardsOpen();

  const setIsViewEligibleGiftCardsOpen =
    useGiftCardsStore().use.actions().setIsViewEligibleGiftCardsOpen;

  const handleCloseDialog = () => {
    setIsViewEligibleGiftCardsOpen(false);
  };

  return (
    <Dialog
      ariaLabel="List of eligible gift cards"
      body={
        <div className={styles['container']}>
          <EligibleGiftCardList />
        </div>
      }
      description="List of eligible gift cards"
      hasCloseIcon
      isOpen={isViewEligibleGiftCardsOpen}
      onOpenChange={handleCloseDialog}
      title="Eligible gift cards"
    />
  );
}
