import { useQueryClient } from '@tanstack/react-query';

import { BankCardsDialogSuccessStates, useBankCardsStore } from '@/store';

import { paymentMethodQueryKeys } from '../../services';
import { useAddCardTracking } from '../../services/use-add-card-tracking/use-add-card-tracking';

export function useAddCardFinalizeSuccess() {
  const queryClient = useQueryClient();
  const { reportAddCardEvent } = useAddCardTracking();

  const {
    setFinalizePayload,
    setIs3dsIframeOpen,
    setIsAddBankCardOpen,
    setIsAddBankCardPending,
    setThreeDSecureId,
  } = useBankCardsStore().use.actions();

  const setBankCardsDialogSuccessState =
    useBankCardsStore().use.actions().setBankCardsDialogSuccessState;

  return function addCardFinalizeCallback() {
    queryClient.invalidateQueries({
      queryKey: paymentMethodQueryKeys.allCardsBank,
    });
    reportAddCardEvent(
      '3ds Completed',
      'Add card finalize complete',
      {},
      {
        passed: 'true',
      }
    );
    setBankCardsDialogSuccessState(BankCardsDialogSuccessStates.BANK_CARD_ADD);
    setIsAddBankCardOpen(false);
    setIs3dsIframeOpen(false);
    setIsAddBankCardPending(false);
    setFinalizePayload(undefined);
    setThreeDSecureId('');
  };
}
