import { BankCardsDialogErrorStates, useBankCardsStore } from '@/store';

import { formatDate } from '../../utils/format-date';

import type { BankCardsTypes } from '../../types/bank-cards-types';
import type { FieldValues } from 'react-hook-form';

export function useAddCardFormSubmit(
  authorizeCard: BankCardsTypes.Operations.AuthorizeBankCard.TMutation,
  publicKey: BankCardsTypes.Operations.GetPublicKey.TResponse | undefined
) {
  const { setBankCardsDialogErrorState, setIsAddBankCardPending } =
    useBankCardsStore().use.actions();

  return async ({
    cardAlias,
    cardExpiryDate,
    cardNumber,
    cardSecurityCode,
  }: FieldValues) => {
    const [expiryMonth, expiryYear] = formatDate(cardExpiryDate);

    if (!expiryMonth || !expiryYear) {
      return;
    }

    if (!publicKey?.key_id || !publicKey?.public_key) {
      setBankCardsDialogErrorState(
        BankCardsDialogErrorStates.BANK_CARD_ADD_ERROR
      );
      return;
    }

    setIsAddBankCardPending(true);

    authorizeCard.mutate({
      data: {
        alias: cardAlias,
        cvv: cardSecurityCode,
        expiryMonth: expiryMonth,
        expiryYear: expiryYear,
        number: cardNumber.replaceAll(' ', ''),
      },
      encryption: publicKey,
    });
  };
}
