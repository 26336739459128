import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { giftCardsQueryKeys } from '../gift-cards-query-keys';

import { useGetGiftCardsProgramsRequest } from './use-get-gift-cards-programs-request';

import type { GiftCardTypes } from '../../types/gift-card-types';
import type { TBaasErrorResponse } from '@/services';
import type { UseQueryOptions } from '@tanstack/react-query';

export function useGetGiftCardsPrograms(
  options?: UseQueryOptions<
    GiftCardTypes.Operations.GetGiftCardPrograms.TResponse,
    TBaasErrorResponse
  >
) {
  const auth = useAuth();

  return useQuery({
    enabled: auth.isAuthenticated,
    queryFn: useGetGiftCardsProgramsRequest(),
    queryKey: giftCardsQueryKeys.allCardsGiftcardsprograms,
    ...options,
  });
}
