import { Button } from '@/components';
import { CheckIcon } from '@/components/icons';

import styles from './bank-cards-dialog-success-content.module.css';

type TBankCardsDialogSuccessContentProps = {
  buttonText: string;
  description?: string;
  handleOpenChange: () => void;
  title: string;
};

export function BankCardsDialogSuccessContent({
  buttonText,
  description,
  handleOpenChange,
  title,
}: TBankCardsDialogSuccessContentProps) {
  return (
    <div className={styles['card-dialog-body-wrapper']}>
      <CheckIcon color={'var(--colors-onSurfaceInverse)'} />

      <div className={styles['card-dialog-body-header']}>
        <h2 className={styles['card-dialog-body-title']}>{title}</h2>

        {!!description && (
          <p className={styles['card-dialog-body-description']}>
            {description}
          </p>
        )}
      </div>

      <Button
        className={styles['action-button']}
        onClick={handleOpenChange}
        size="medium"
      >
        {buttonText}
      </Button>
    </div>
  );
}
