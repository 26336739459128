import { differenceInDays, format, formatDistanceStrict } from 'date-fns';

import { DateFormats } from '@/config/language';

export function timeUntilExpiry(expiryDateTime: string) {
  const dayDifference = differenceInDays(expiryDateTime, new Date());

  if (isNaN(dayDifference)) {
    return '';
  }

  const formattedDate = format(
    expiryDateTime,
    DateFormats.DAY_MONTH_YEAR_LONG_ALT
  );

  const formattedDateSoon = formatDistanceStrict(expiryDateTime, new Date());

  if (dayDifference < 1) {
    return 'today';
  }

  if (dayDifference <= 7) {
    return `in ${formattedDateSoon}`;
  }

  return formattedDate;
}
