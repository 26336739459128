import { ConfirmationDialog } from '@/components';
import { useAnalytics } from '@/hooks';
import { useBankCardsStore } from '@/store';

import { paymentMethodQueryKeys } from '../../services/query-keys';
import { useDeleteBankCard } from '../../services/use-delete-bank-card/use-delete-bank-card';
import { useGetBankCards } from '../../services/use-get-bank-cards/use-get-bank-cards';
import { select } from '../../services/use-get-bank-cards/use-get-bank-cards-selector';
import { useSetDefaultBankCard } from '../../services/use-set-default-bank-card/use-set-default-bank-card';
import { CardListDialog } from '../card-list-dialog/card-list-dialog';
import { EditCard } from '../edit-card/edit-card';
import { EditCardNicknameForm } from '../edit-card-nickname-form/edit-card-nickname-form';
import { ViewBankCardBody } from '../view-bank-card-body/view-bank-card-body';
import { ViewCardFooter } from '../view-bank-card-footer/view-card-footer';

import styles from './view-bank-card.module.css';

export function ViewBankCard() {
  const getRemovableBankCards = useGetBankCards({
    queryKey: paymentMethodQueryKeys.allCardsBank,
    select: select.removableCards,
  });

  const removableBankCards = getRemovableBankCards?.data?.cards;

  const { track } = useAnalytics();

  const isEditingCard = useBankCardsStore().use.isEditingCard();
  const isEditingCardNickname = useBankCardsStore().use.isEditingCardNickname();
  const isOpenDeleteDialog = useBankCardsStore().use.isOpenDeleteDialog();
  const selectedBankCard = useBankCardsStore().use.selectedBankCard();

  const { setIsOpenBankCardsList, setIsOpenDeleteDialog } =
    useBankCardsStore().use.actions();

  const deleteBankCard = useDeleteBankCard(selectedBankCard?.id || '');

  const setDefaultCardBeforeDelete = useSetDefaultBankCard(
    (removableBankCards && removableBankCards?.[0]?.id) || '',
    {
      onSuccess: () => {
        deleteBankCard?.mutate();
      },
    }
  );

  const handleCancelDeleteCard = () => setIsOpenDeleteDialog(false);

  const handleDeleteCard = () => {
    track('Delete Card Confirmed');

    if (!!selectedBankCard?.is_default_pay) {
      if (!removableBankCards?.length) {
        deleteBankCard?.mutate();
      } else if (removableBankCards?.length === 1) {
        setDefaultCardBeforeDelete.mutate();
      } else {
        setIsOpenBankCardsList(true);
        setIsOpenDeleteDialog(false);
      }
    } else {
      deleteBankCard?.mutate();
    }
  };

  if (isEditingCard && !!selectedBankCard) {
    return <EditCard />;
  }

  if (isEditingCardNickname && !!selectedBankCard) {
    return <EditCardNicknameForm />;
  }

  return (
    <div className={styles['view-bank-card']}>
      <ConfirmationDialog
        acceptButtonText="Yes, remove it"
        isDanger
        isOpen={isOpenDeleteDialog}
        loading={deleteBankCard.isPending}
        onClickAccept={handleDeleteCard}
        onClickCancel={handleCancelDeleteCard}
        title="Are you sure you want to remove this card?"
      />

      <CardListDialog cards={removableBankCards} />

      <ViewBankCardBody />
      <ViewCardFooter />
    </div>
  );
}
