import { giftCardsSlots } from './gift-cards-slots';
import { sortGiftCards } from './sort-gift-cards';

import type { GiftCardTypes } from '../../types/gift-card-types';

export function transformGiftCards(
  response: GiftCardTypes.Operations.GetGiftCards.TResponse
): GiftCardTypes.Operations.GetGiftCards.TResponse {
  response.cards = sortGiftCards(response.cards);
  return giftCardsSlots.addGiftCardsSlotAttributes(response);
}
