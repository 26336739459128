import styled from 'styled-components';

import { BreakpointRems } from '@/config/breakpoints';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
  padding: 0 var(--space-l);

  @media (min-width: ${BreakpointRems.md}) {
    padding: 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 3rem;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

const Panel = styled.div`
  display: flex;
  padding: var(--space-xl) var(--space-l);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-xl);
  border-radius: var(--radii-lg);
  background: var(--light-surface-default, #fff);
  width: 100%;

  @media (min-width: ${BreakpointRems.md}) {
    width: 32.5rem;
  }

  > [aria-haspopup='dialog'] {
    display: none;
  }
`;

const PanelHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const PanelContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
  align-items: flex-start;

  h3 {
    word-break: break-all;
  }
`;

const DisplayNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-2xs);
`;

const DisplayNameHeader = styled.h3`
  color: var(--colors-onSurfaceA);
  font-size: var(--fontSizes-14);
  font-family: var(--fonts-sans);
  font-style: normal;
  font-weight: var(--fontWeights-bold);
  line-height: var(--lineHeights-half);
  margin: 0;

  @media (min-width: ${BreakpointRems.md}) {
    font-size: var(--fontSizes-16);
  }
`;

const DisplayName = styled.p`
  color: var(--colors-onSurfaceB);
  font-size: var(--fontSizes-14);
  font-family: var(--fonts-sans);
  font-style: normal;
  font-weight: var(--fontWeights-normal);
  line-height: var(--lineHeights-half);
  margin: 0;
`;

const PanelTitle = styled.h2`
  font-size: var(--fontSizes-20);
  font-family: var(--fonts-sans);
  font-style: normal;
  font-weight: var(--fontWeights-bold);
  line-height: var(--lineHeights-half);
  margin: 0;
`;

const ButtonIconSpacer = styled.div`
  width: var(--space-2xs);
`;

const DisplayTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-m);
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: var(--space-s);

  @media (min-width: ${BreakpointRems.md}) {
    flex-direction: initial;
    justify-content: space-between;
  }

  button {
    line-height: 1;
  }
`;

const UpdateContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
`;

const UpdateContentTitle = styled.div`
  font-size: var(--fontSizes-16);
  font-style: normal;
  font-weight: var(--fontWeights-normal);
  line-height: 1.2;
  color: var(--colors-onSurfaceB);
`;

const Fieldset = styled.fieldset`
  border: 0;
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
  margin: 0;
  padding: 0;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: var(--space-xl);
  gap: var(--space-l);
`;

const ErrorText = styled.p`
  color: var(--colors-onErrorLow);
  font-size: var(--fontSizes-14);
  font-style: normal;
  font-weight: var(--fontWeights-normal);
  line-height: 1.2;
  padding: 0;
  margin: 0;

  a {
    color: inherit;
  }
`;

const ErrorMessageWrapper = styled.div`
  display: flex;
  gap: var(--space-2xs);
`;

const ErrorMessageIconWrapper = styled.div``;

const ProfileFormRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
`;

export const Styled = {
  ButtonIconSpacer,
  Container,
  ContentWrapper,
  DisplayName,
  DisplayNameHeader,
  DisplayNameWrapper,
  DisplayTextWrapper,
  ErrorMessageIconWrapper,
  ErrorMessageWrapper,
  ErrorText,
  Fieldset,
  Footer,
  Panel,
  PanelContent,
  PanelHeader,
  PanelTitle,
  ProfileFormRow,
  Row,
  TitleWrapper,
  UpdateContentTitle,
  UpdateContentWrapper,
};
