import clsx from 'clsx';
import { useState } from 'react';

import { CloseIcon, ExclamationMarkIcon, TickIcon } from '../icons';

import styles from './callout.module.css';
import { calloutVariants } from './callout.variants';

enum CalloutType {
  DEFAULT = 'default',
  ERROR = 'error',
  SUCCESS = 'success',
}

export enum CalloutSize {
  MEDIUM = 'md',
  SMALL = 'sm',
}

type TCalloutProps = {
  canClose?: boolean;
  cta: React.ReactNode;
  description?: string;
  size?: `${CalloutSize}`;
  title?: string;
  type?: `${CalloutType}`;
};

const CalloutIcons: Record<CalloutType, React.ReactElement> = {
  [CalloutType.DEFAULT]: <ExclamationMarkIcon height={16} width={16} />,
  [CalloutType.ERROR]: (
    <CloseIcon color="var(--colors-errorHigh)" height={16} width={16} />
  ),
  [CalloutType.SUCCESS]: (
    <TickIcon color={'var(--colors-successHigh)'} height={16} width={16} />
  ),
};

export function Callout({
  canClose = false,
  cta,
  description,
  size = 'md',
  title,
  type = 'default',
}: TCalloutProps) {
  const [isOpen, setIsOpen] = useState(true);

  if (!isOpen) {
    return null;
  }

  const calloutStyles = clsx(calloutVariants({ size, type }));

  const handleOnClose = () => {
    setIsOpen(false);
  };

  return (
    <div className={calloutStyles}>
      <div className={styles['icon']}>{CalloutIcons[type]}</div>
      <div className={styles['callout-content']}>
        {!!title && <h1 className={styles['title']}>{title}</h1>}
        {!!description && (
          <p className={styles['description']}>{description}</p>
        )}
        <div className={styles['cta']}>{cta}</div>
      </div>
      {canClose && (
        <button
          aria-label="Close callout"
          className={styles['close-button']}
          onClick={handleOnClose}
          type="button"
        >
          <CloseIcon height={20} width={20} />
        </button>
      )}
    </div>
  );
}
