import clsx from 'clsx';
import { differenceInDays, format } from 'date-fns';
import { capitalize } from 'inflection';

import { Callout } from '@/components';
import { DateFormats } from '@/config/language';
import { useGiftCardsStore } from '@/store';

import { GiftCardArt } from '../gift-card-art/gift-card-art';
import { GiftCardStatus } from '../gift-card-status/gift-card-status';

import styles from './gift-card-details-body.module.css';

export function GiftCardDetailsBody() {
  const giftCard = useGiftCardsStore().use.selectedGiftCard();

  if (!giftCard) {
    return <div />;
  }

  const daysLeft = !!giftCard.live_data?.expiry_datetime
    ? differenceInDays(giftCard.live_data.expiry_datetime, new Date())
    : 0;

  const underWeek = daysLeft < 7;

  const hideStatus =
    giftCard.isExpired || giftCard.isInvalid || giftCard.isUsed;

  const showExpiringSoon =
    !hideStatus && underWeek && !!giftCard.expires?.timeLeft;

  const balanceDateTime = !!giftCard.live_data?.balance_datetime
    ? format(
        giftCard.live_data?.balance_datetime,
        DateFormats.DAY_MONTH_YEAR_TIME
      )
    : format(new Date(), DateFormats.DAY_MONTH_YEAR_TIME);

  return (
    <div className={styles['card-details-container']}>
      <GiftCardArt
        imageUrl={giftCard.program?.display?.card_art_url}
        size={'lg'}
      />

      <div className={styles['card-details']} role="list">
        <div className={styles['card-details-row']}>
          <div className={styles['card-details-column']} role="listitem">
            <strong>
              {capitalize(giftCard.program?.provider_id)} gift card
            </strong>
          </div>
          {hideStatus && (
            <div
              className={clsx(
                styles['card-details-column'],
                styles['card-details-status']
              )}
              role="listitem"
            >
              <GiftCardStatus giftCard={giftCard} />
            </div>
          )}
        </div>
        <div className={styles['card-details-row']}>
          <div className={styles['card-details-column']} role="listitem">
            <div className={styles['card-details-column-label']}>
              Card balance (as of {balanceDateTime})
            </div>
            <p
              className={clsx(
                styles['card-details-column-text'],
                styles['balance']
              )}
            >
              <strong>{giftCard.currentBalance}</strong>
            </p>
          </div>
        </div>
        <div className={styles['card-details-row']}>
          <div className={styles['card-details-column']} role="listitem">
            <div className={styles['card-details-column-label']}>
              Card number
            </div>
            <p className={styles['card-details-column-text']}>
              •••• {giftCard.last4Digits}
            </p>
          </div>
          {giftCard?.expires?.formattedDate && (
            <div className={styles['card-details-column']} role="listitem">
              <div className={styles['card-details-column-label']}>Expiry</div>
              <p className={styles['card-details-column-text']}>
                {giftCard.expires?.formattedDate}
              </p>
            </div>
          )}
        </div>
        {showExpiringSoon && (
          <div className={styles['card-details-row']}>
            <div className={styles['card-details-column']} role="listitem">
              <Callout
                cta={
                  <>
                    {`This gift card is expiring ${giftCard?.expires?.timeLeft}`}
                  </>
                }
                size="sm"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
