import styled from 'styled-components';

import { BreakpointRems } from '@/config/breakpoints';

const ProfileDetailsWrapper = styled.div<{ $avatarLeft?: boolean }>`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  gap: var(--space-m);

  ${({ $avatarLeft }) => $avatarLeft && `flex-direction: row-reverse;`}
  @media (max-width: ${BreakpointRems['2xs']}) {
    width: 100%;
    margin-left: var(--space-m);
  }
`;

const DetailsTextWrapper = styled.div<{ $avatarLeft?: boolean }>`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: ${({ $avatarLeft }) =>
    $avatarLeft ? 'flex-start' : 'flex-end'};
`;

const DetailsTextWrapperHeading = styled.h1<{ $color?: string }>`
  font-size: var(--fontSizes-24);
  font-weight: var(--fontWeights-normal);
  color: var(--colors-${({ $color }) => (!!$color ? $color : 'brand1Highest')});
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
  text-align: end;
  max-width: 250px;

  @media (max-width: ${BreakpointRems.xs}) {
    width: 160px;
  }

  @media (max-width: ${BreakpointRems['2xs']}) {
    width: 140px;
  }
`;

const DetailsTextWrapperText = styled.p<{ $color?: string }>`
  font-size: var(--fontSizes-12);
  font-weight: var(--fontWeights-normal);
  line-height: var(--lineHeights-normal);
  margin: 0;
  color: var(--colors-${({ $color }) => (!!$color ? $color : 'brand1Highest')});
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: end;
`;

export const Styled = {
  DetailsTextWrapper,
  DetailsTextWrapperHeading,
  DetailsTextWrapperText,
  ProfileDetailsWrapper,
};
