import isEmpty from 'lodash.isempty';

import { Button } from '@/components';
import { ErrorDialogBody } from '@/components/dialog/error-dialog';
import { genericContent } from '@/config/language';
import { useGiftCardsStore } from '@/store';

import { useGetGiftCardsPrograms } from '../../services/use-get-gift-cards-programs/use-get-gift-cards-programs';
import { EligibleGiftCardListItem } from '../eligible-gift-card-list-item/eligible-gift-card-list-item';

import styles from './eligible-gift-card-list.module.css';

export function EligibleGiftCardList() {
  const programs = useGetGiftCardsPrograms();

  const setIsViewEligibleGiftCardsOpen =
    useGiftCardsStore().use.actions().setIsViewEligibleGiftCardsOpen;

  const handleCloseDialog = () => {
    setIsViewEligibleGiftCardsOpen(false);
  };

  if (
    programs.isError ||
    !programs?.data?.programs ||
    isEmpty(programs.data.programs)
  ) {
    return (
      <div>
        <p className={styles['paragraph']}>
          Add up to 5 gift cards from the list below.
        </p>
        <ErrorDialogBody
          buttonText="Okay, Got it"
          message={genericContent.ERROR_GENERIC_MESSAGE}
          onOpenChange={handleCloseDialog}
          title={genericContent.ERROR_GENERIC_TITLE}
          tryAgain={false}
        />
      </div>
    );
  }

  return (
    <>
      <p className={styles['paragraph']}>
        Add up to 5 gift cards from the list below.
      </p>
      <div>
        <ul className={styles['list']}>
          {programs.data.programs.map((program) => (
            <EligibleGiftCardListItem key={program.id} program={program} />
          ))}
        </ul>
      </div>
      <div>
        <Button onClick={handleCloseDialog} size="medium">
          Okay, got it
        </Button>
      </div>
    </>
  );
}
