import { format } from 'date-fns';

import { DateFormats } from '@/config/language';

import { formatGiftCardBalance } from '../../utils/format-gift-card-balance';
import { timeUntilExpiry } from '../../utils/time-until-expiry';

import type { GiftCardTypes } from '../../types/gift-card-types';

export const giftCardDetails = {
  addGiftCardDetails(card: GiftCardTypes.Components.TGiftCard) {
    return {
      ...card,
      currentBalance: giftCardDetails.currentBalance(card),
      expires: giftCardDetails.getExpiry(card),
      last4Digits: giftCardDetails.last4Digits(card),
    };
  },

  currentBalance(card: GiftCardTypes.Components.TGiftCard) {
    return !!card?.live_data
      ? formatGiftCardBalance(card.live_data.current_balance)
      : '';
  },

  getExpiry(card: GiftCardTypes.Components.TGiftCard) {
    return !!card?.live_data?.expiry_datetime
      ? {
          formattedDate: format(
            card.live_data.expiry_datetime,
            DateFormats.DAY_MONTH_YEAR_LONG_ALT
          ),
          timeLeft: timeUntilExpiry(card.live_data.expiry_datetime),
        }
      : {};
  },

  last4Digits(card: GiftCardTypes.Components.TGiftCard) {
    return !!card?.data?.visualization?.masked_card_number
      ? card.data.visualization.masked_card_number.slice(-4)
      : '';
  },
};
