import {
  ExclamationMarkIcon,
  ExclamationMarkTriangleIcon,
} from '@/components/icons';
import { genericContent } from '@/config/language';
import {
  BUTTON_TEXT_TRY_AGAIN,
  ERROR_DELETE_CARD_GENERIC,
  ERROR_SAVE_CARD_GENERIC,
  ERROR_SET_DEFAULT_CARD_ALREADY_DEFAULT,
  ERROR_SET_DEFAULT_CARD_FAILED,
  ERROR_TITLE_DELETE_CARD,
  ERROR_TITLE_SAVE_CARD,
  ERROR_UPDATE_CARD_NICKNAME,
  TITLE_SET_DEFAULT_CARD_ALREADY_DEFAULT,
} from '@/config/language/errors';
import { BankCardsDialogErrorStates } from '@/store';

type TCardDialogErrorRecord = {
  buttonText: string | undefined;
  description: React.ReactNode;
  icon?: React.ReactNode;
  isShowSupportContact: boolean;
  title: string;
  tryAgainMessage?: string;
};

type TBankCardsDialogErrorProps = Record<
  keyof typeof BankCardsDialogErrorStates,
  (() => TCardDialogErrorRecord) | undefined
>;

export const bankCardsDialogErrorProps: TBankCardsDialogErrorProps = {
  [BankCardsDialogErrorStates.BANK_CARD_ADD_ERROR]() {
    return {
      buttonText: BUTTON_TEXT_TRY_AGAIN,
      description: ERROR_SAVE_CARD_GENERIC,
      icon: <ExclamationMarkTriangleIcon color="var(--colors-errorHigh)" />,
      isShowSupportContact: true,
      title: ERROR_TITLE_SAVE_CARD,
    };
  },

  [BankCardsDialogErrorStates.BANK_CARD_COUNT_LIMIT_REACHED]() {
    return {
      buttonText: 'OK',
      description:
        'You can store a maximum of 5 cards in your wallet. Delete a card first if you want to add a new one.',
      icon: <ExclamationMarkIcon />,
      isShowSupportContact: false,
      title: 'Card limit reached',
    };
  },

  [BankCardsDialogErrorStates.BANK_CARD_DELETE_ERROR]() {
    return {
      buttonText: 'OK',
      description: ERROR_DELETE_CARD_GENERIC,
      icon: <ExclamationMarkTriangleIcon color="var(--colors-errorHigh)" />,
      isShowSupportContact: true,
      title: ERROR_TITLE_DELETE_CARD,
    };
  },

  [BankCardsDialogErrorStates.BANK_CARD_EDIT_ERROR]() {
    return {
      buttonText: undefined,
      description: ERROR_SAVE_CARD_GENERIC,
      icon: <ExclamationMarkTriangleIcon color="var(--colors-errorHigh)" />,
      isShowSupportContact: true,
      title: genericContent.ERROR_GENERIC_TITLE,
    };
  },

  [BankCardsDialogErrorStates.BANK_CARD_EDIT_NICKNAME_ERROR]() {
    return {
      buttonText: undefined,
      description: ERROR_UPDATE_CARD_NICKNAME,
      icon: <ExclamationMarkTriangleIcon color="var(--colors-errorHigh)" />,
      isShowSupportContact: true,
      title: genericContent.ERROR_GENERIC_TITLE,
    };
  },

  [BankCardsDialogErrorStates.BANK_CARD_SET_DEFAULT_ERROR]() {
    return {
      buttonText: 'Continue',
      description: ERROR_SET_DEFAULT_CARD_FAILED,
      icon: <ExclamationMarkTriangleIcon color="var(--colors-errorHigh)" />,
      isShowSupportContact: true,
      title: genericContent.ERROR_GENERIC_TITLE,
    };
  },

  [BankCardsDialogErrorStates.BANK_CARD_SET_DEFAULT_ALREADY_DEFAULT_ERROR]() {
    return {
      buttonText: 'Continue',
      description: <ERROR_SET_DEFAULT_CARD_ALREADY_DEFAULT />,
      icon: <ExclamationMarkIcon />,
      isShowSupportContact: false,
      title: TITLE_SET_DEFAULT_CARD_ALREADY_DEFAULT,
    };
  },

  [BankCardsDialogErrorStates.NULL]: undefined,
};
