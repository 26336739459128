import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { ErrorFallback } from '@/components';
import { GlobalStyles, getDefaultTheme } from '@/theme';

import { ContextProviders } from './contexts';
import { AppRoutes } from './routes';

import './theme/theme-tokens.css';

export const App = () => {
  const initialTheme = getDefaultTheme();

  // Prevents iframe injection
  useEffect(() => {
    if (window.top !== window.self && window.top) {
      window.top.location = window.self.location;
    }
  }, []);

  return (
    <ContextProviders>
      <GlobalStyles theme={initialTheme} />
      <ReactQueryDevtools initialIsOpen={false} />

      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AppRoutes />
      </ErrorBoundary>
    </ContextProviders>
  );
};
