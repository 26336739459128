import { BankCardsDialogSuccessStates } from '@/store';

import { useBankCardShowSuccessDialog } from '../../hooks/use-bank-card-show-success-dialog';
import { useViewCardReset } from '../../hooks/use-view-card-reset';

export function useEditCardFinalizeSuccess() {
  const showBankCardSuccessDialog = useBankCardShowSuccessDialog();
  const viewCardReset = useViewCardReset();

  return function editCardDetailsFinalizeSuccessCallback() {
    viewCardReset();
    showBankCardSuccessDialog(BankCardsDialogSuccessStates.BANK_CARD_EDIT);
  };
}
