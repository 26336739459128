import { formatDate } from '../../utils/format-date';

import type { BankCardsTypes } from '../../types/bank-cards-types';
import type { FieldValues } from 'react-hook-form';

export function useEditCardFormSubmit(
  updateBankCard: BankCardsTypes.Operations.UpdateBankCard.TMutation
) {
  return async function ({ cardExpiryDate, cardSecurityCode }: FieldValues) {
    const [expiryMonth, expiryYear] = formatDate(cardExpiryDate);

    if (!expiryMonth || !expiryYear) {
      return;
    }

    const cardDetails = {
      cvv: cardSecurityCode,
      expiry: {
        month: expiryMonth,
        year: expiryYear,
      },
    };

    updateBankCard.mutate({
      card: cardDetails,
    });
  };
}
