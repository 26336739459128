import { useMutationState } from '@tanstack/react-query';
import { useEffect } from 'react';

import { GiftCardsDialogSuccessStates, useGiftCardsStore } from '@/store';

import { giftCardsQueryKeys } from '../../services/gift-cards-query-keys';

export function useAddGiftCardDialogSuccess() {
  const addGiftCardMutationStatus = useMutationState({
    filters: { mutationKey: giftCardsQueryKeys.cardsGiftcardsAdd },
    select: (mutation) => mutation.state.status,
  });

  const { setGiftCardsDialogSuccessState } = useGiftCardsStore().use.actions();

  useEffect(() => {
    if (addGiftCardMutationStatus?.at(0) === 'success') {
      setGiftCardsDialogSuccessState(
        GiftCardsDialogSuccessStates.GIFT_CARD_ADD
      );
    }
  }, [addGiftCardMutationStatus, setGiftCardsDialogSuccessState]);
}
