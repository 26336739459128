import { FilterFormBodyAmount } from './filter-form-body-amount';
import { FilterFormBodyDate } from './filter-form-body-date';

export function FilterFormBody() {
  return (
    <div>
      <FilterFormBodyDate />
      <FilterFormBodyAmount />
      {/* 
          TODO: FLY-3048 - re-enable when coles launches instore
          <FilterFormBodyOrderType /> 
          */}
    </div>
  );
}
