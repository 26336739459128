import { camelize } from 'inflection';
import { useEffect } from 'react';

import { authClientId } from '@/services/auth';

import type { WalletConfigOpenTypes } from '@/features/wallet-config';

const applyThemeColors = (
  colors: WalletConfigOpenTypes.Components.TWalletOpenConfigPalette
) => {
  const head = document.getElementsByTagName('head')[0];
  const style = document.createElement('style');
  style.append('body {');
  for (const [key, value] of Object.entries(colors)) {
    style.append(`--colors-${camelize(key, true)}: ${value};`);
  }
  style.append('}');
  head.appendChild(style);
};

export const useWalletConfiguration = (
  config?: WalletConfigOpenTypes.Operations.GetWalletConfigOpen.TResponse
) => {
  useEffect(() => {
    const colors = config?.appearance?.theme?.palette?.light;
    if (!!authClientId && !!colors) {
      applyThemeColors(colors);
    }
  }, [config]);
};
