import { Pill } from '@/components';

import { getBankCardStatus } from '../../utils/get-bank-card-status';

import { getCardStatusPillVariant } from './get-card-status-pill-variant';

import type { BankCardsTypes } from '@/features/bank-cards/types/bank-cards-types';

type TCardPillProps = {
  isDefault?: boolean;
  size?: 'sm' | 'xs';
  status: BankCardsTypes.Components.TBankCardStatus;
};

export function CardStatusPill({
  isDefault = false,
  size = 'xs',
  status,
}: TCardPillProps) {
  const cardStatusPillText = getBankCardStatus(status, isDefault);
  const cardStatusPillVariant = getCardStatusPillVariant(status, isDefault);

  return (
    <Pill
      rootCSS={{
        borderRadius: '120px',
        justifyContent: 'center',
        width: 'fit-content',
      }}
      size={size}
      variant={cardStatusPillVariant}
    >
      {cardStatusPillText}
    </Pill>
  );
}
