import { useQueryClient } from '@tanstack/react-query';

import { LoadingCard } from '@/components';
import { PaymentMethodEnums } from '@/features/bank-cards';
import { useGetFlybuysCards } from '@/features/loyalty/services';
import { loyaltyQueryKeys } from '@/features/loyalty/services/query-keys';
import { useHasUserConsentedTerms } from '@/features/profile';
import { isCardErrorTnc } from '@/utils';

import { FlybuysFetchError } from './fetch-error/flybuys-fetch-error';

import { FlybuysLinked, FlybuysNotLinked } from '.';

export const Flybuys = () => {
  const { data, error, isError, isLoading } = useGetFlybuysCards();
  const consentedTerms = useHasUserConsentedTerms();
  const queryClient = useQueryClient();

  const isGetFlybuysCardsLoading =
    !consentedTerms || isCardErrorTnc(error, isLoading, isError);

  if (isGetFlybuysCardsLoading) {
    return <LoadingCard />;
  }

  if (isError) {
    return <FlybuysFetchError />;
  }

  const linkedCard = data?.cards?.find(
    (card) => card.status === PaymentMethodEnums.LoyaltyCardStatus.LINKED
  );

  const hasExpiredRefreshToken = !!linkedCard && !linkedCard?.live?.status;

  const pendingLinkCard = data?.cards?.find(
    (card) => card.status === PaymentMethodEnums.LoyaltyCardStatus.PENDING_LINK
  );
  const reloadLoyaltyCardList = () => {
    queryClient.invalidateQueries({
      queryKey: loyaltyQueryKeys.flybuysCards,
    });
  };

  if (linkedCard && !hasExpiredRefreshToken) {
    return (
      <FlybuysLinked
        card={linkedCard}
        reloadLoyaltyCardList={reloadLoyaltyCardList}
      />
    );
  }

  return (
    <FlybuysNotLinked
      hasExpiredRefreshToken={hasExpiredRefreshToken}
      linkedCard={linkedCard}
      pendingLinkCard={pendingLinkCard}
      reloadLoyaltyCardList={reloadLoyaltyCardList}
    />
  );
};
