import { AddIcon } from '../icons';

import styles from './cards-panel-button.module.css';
import { Styled } from './styles';

type TCardsPanelButton = {
  addLabel: string;
  handleAddCardOpen: () => void;
};

export function CardsPanelButton({
  addLabel,
  handleAddCardOpen,
}: TCardsPanelButton) {
  return (
    <Styled.AddBtn
      aria-label={`Add ${addLabel}`}
      level="tertiary"
      onClick={handleAddCardOpen}
      variant="branded"
    >
      <div className={styles['panel-card-button-wrapper']}>
        <AddIcon /> Add
      </div>
    </Styled.AddBtn>
  );
}
