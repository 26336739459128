import { transformGiftCard } from './transform-gift-card';
import { transformGiftCards } from './transform-gift-cards';

import type { GiftCardTypes } from '../../types/gift-card-types';

export const select = {
  sortedGiftCards(response: GiftCardTypes.Operations.GetGiftCards.TResponse) {
    const giftCards = transformGiftCards(response);

    giftCards.cards = giftCards.cards.map(
      (card: GiftCardTypes.Components.TGiftCard) => transformGiftCard(card)
    );

    return giftCards;
  },
};
