import { useBankCardsStore } from '@/store';

import { useAddCardTracking } from '../use-add-card-tracking/use-add-card-tracking';

import type { BankCardsTypes } from '../../types/bank-cards-types';

export function useAuthorizeCardSuccess() {
  const selectedBankCard = useBankCardsStore().use.selectedBankCard();
  const { setFinalizePayload, setIs3dsIframeOpen, setThreeDSecureId } =
    useBankCardsStore().use.actions();

  const { reportAddCardEvent } = useAddCardTracking();

  return function addCardAuthorizeSuccessCallback(
    data: BankCardsTypes.Operations.AuthorizeBankCard.TResponse
  ) {
    reportAddCardEvent('3ds Start', 'Add card authorize complete');

    setThreeDSecureId(data?.three_d_secure?.id);
    setFinalizePayload({
      authorization_id: data?.three_d_secure?.id,
      is_default_pay: !!selectedBankCard?.is_default_pay,
      payer: {
        card: {
          id: data?.card_id,
        },
      },
    });
    setIs3dsIframeOpen(true);
  };
}
