import { createGlobalStyle } from 'styled-components';

import { normalize } from './normalize';

const FONT_URL = 'https://static.beemit.com.au/fonts/fabric';
const FONT_FAMILY_SANS = 'Fabric Sans Web';
const FONT_FAMILY_SERIF = 'Fabric Serif Web';

export const GlobalStyles = createGlobalStyle`
  ${normalize}
  
  @font-face {
    font-display: swap;
    font-family: ${FONT_FAMILY_SANS};
    font-style: normal;
    font-weight: 500;
    src: url('${FONT_URL}/FabricSansWeb-Medium.woff2') format('woff2'),
        url('${FONT_URL}/FabricSansWeb-Medium.woff') format('woff');
  }
  
  @font-face {
    font-display: swap;
    font-family: ${FONT_FAMILY_SANS};
    font-style: normal;
    font-weight: normal;
    src: url('${FONT_URL}/FabricSansWeb-Regular.woff2') format('woff2'),
       url('${FONT_URL}/FabricSansWeb-Regular.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: ${FONT_FAMILY_SANS};
    font-style: normal;
    font-weight: bold;
    src: url('${FONT_URL}/FabricSansWeb-Bold.woff2') format('woff2'),
       url('${FONT_URL}/FabricSansWeb-Bold.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: ${FONT_FAMILY_SERIF};
    font-style: normal;
    font-weight: normal;
    src: url('${FONT_URL}/FabricSerifWeb-Regular.woff2') format('woff2'),
        url('${FONT_URL}/FabricSerifWeb-Regular.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: ${FONT_FAMILY_SERIF};
    font-style: normal;
    font-weight: 500;
    src: url('${FONT_URL}/FabricSerifWeb-Medium.woff2') format('woff2'),
      url('${FONT_URL}/FabricSerifWeb-Medium.woff') format('woff');
  }

  html {
    box-sizing: border-box;
    font-size: var(--fontSizes-16);
  }

  *, 
  *::before, 
  *::after {
    box-sizing: inherit;
  }
  
  body {
    background-color: var(--colors-surfaceLow, #F9F9FB);
    color: var(--colors-onSurfaceA, #000);
    font-family: ${`var(--fonts-sans, ${FONT_FAMILY_SANS})`};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
  }

  main {
    margin: 0 auto;
  }
`;
