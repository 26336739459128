import { Dialog } from '@/components';
import { ExclamationMarkTriangleIcon } from '@/components/icons';
import { useGetWalletConfigOpen } from '@/features/wallet-config';
import { GiftCardsDialogErrorStates, useGiftCardsStore } from '@/store';

import { GiftCardsDialogErrorContent } from './gift-cards-dialog-error-content';
import { giftCardsDialogErrorCopy } from './gift-cards-dialog-error-copy';
import { useGiftCardsDialogErrorReset } from './use-gift-cards-dialog-error-reset';

export function GiftCardsDialogError() {
  const getWalletConfig = useGetWalletConfigOpen();

  const giftCardsDialogErrorState =
    useGiftCardsStore().use.giftCardsDialogErrorState();

  const isOpenGiftCardsDialogError =
    giftCardsDialogErrorState !== GiftCardsDialogErrorStates.NULL;

  const giftCardDialogErrorProps = giftCardsDialogErrorCopy[
    giftCardsDialogErrorState
  ]?.(getWalletConfig?.data?.wallet_name);

  const handleOpenChange = useGiftCardsDialogErrorReset();

  return (
    <Dialog
      body={
        <GiftCardsDialogErrorContent
          buttonText={giftCardDialogErrorProps?.buttonText || 'Okay'}
          description={giftCardDialogErrorProps?.description}
          handleOpenChange={handleOpenChange}
          icon={<ExclamationMarkTriangleIcon color="var(--colors-errorHigh)" />}
          title={giftCardDialogErrorProps?.title || ''}
        />
      }
      description={giftCardDialogErrorProps?.title}
      isOpen={isOpenGiftCardsDialogError}
      onOpenChange={handleOpenChange}
    />
  );
}
