import { useState } from 'react';
import { useAuth } from 'react-oidc-context';

import { Layout, LoadingScreen, SideSheet } from '@/components';
import { Filters, OrdersTable } from '@/features/activity';
import { useGetOrders } from '@/features/activity/services';
import { getTotalActiveFilters } from '@/features/activity/utils/get-total-active-filters';
import { usePageTitle } from '@/hooks/use-page-title';
import { useActivityStore } from '@/store';

import { ActivityHeader } from './activity-header';
import { OrderDetails } from './order-details';
import { Styled } from './styles';
import { getOrderById } from './utils';
import { formatActiveFilters } from './utils/format-filters';
import { getOrderQuery } from './utils/get-order-query';
import { getOrderSubhead } from './utils/get-order-subhead';

import type { TSearchQuery } from './types';
import type { OrderTypes } from '@/features/activity/types';

const initialSearchQuery = {
  category: '',
  query: '',
};

export const Activity = () => {
  usePageTitle('Activity');
  const auth = useAuth();
  const [searchQuery, setSearchQuery] =
    useState<TSearchQuery>(initialSearchQuery);
  const activeFilters = useActivityStore().use.activeFilters();

  const [isOpenOrderDetails, setIsOpenOrderDetails] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] =
    useState<OrderTypes.TOrderInfo | null>(null);

  const {
    data: ordersList,
    fetchNextPage,
    hasNextPage,
    isError,
    isFetchingNextPage,
    isLoading: isLoadingOrders,
  } = useGetOrders({
    params: {
      ...formatActiveFilters(activeFilters),
      ...getOrderQuery(searchQuery),
      status: ['pending', 'finalized', 'aborted'],
    },
  });

  if (auth.isLoading) {
    return (
      <Layout>
        <LoadingScreen />
      </Layout>
    );
  }

  const handleViewOrder = (id: string) => {
    const order = getOrderById(id, ordersList?.orders);
    if (!!order) {
      setSelectedOrder(order);
      setIsOpenOrderDetails(true);
    }
  };

  const handleOnClearSearch = () => {
    setSearchQuery((query) => ({ category: query.category, query: '' }));
  };

  return (
    <Layout>
      <Styled.ActivityWrapper>
        <Styled.HeaderContainer>
          <ActivityHeader
            filterNumber={getTotalActiveFilters(activeFilters)}
            initialSearchQuery={searchQuery}
            onClearSearch={handleOnClearSearch}
            onSearch={setSearchQuery}
            orderSubhead={getOrderSubhead(activeFilters, searchQuery?.query)}
          />
        </Styled.HeaderContainer>
        <Styled.TableWrapper>
          <OrdersTable
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isError={isError}
            isFetchingNextPage={isFetchingNextPage}
            isLoading={isLoadingOrders}
            onSelectOrder={handleViewOrder}
            orders={ordersList?.groupedOrders}
          />
        </Styled.TableWrapper>
      </Styled.ActivityWrapper>
      <Filters />
      <SideSheet
        onClose={() => setIsOpenOrderDetails(false)}
        open={isOpenOrderDetails}
        title="Activity details"
      >
        {!!selectedOrder && <OrderDetails order={selectedOrder} />}
      </SideSheet>
    </Layout>
  );
};
