import { useQueryClient } from '@tanstack/react-query';

import { useGiftCardsStore } from '@/store';

import { giftCardsQueryKeys } from '../gift-cards-query-keys';

import type { GiftCardTypes } from '../../types/gift-card-types';

export function useAddGiftCardSuccess() {
  const queryClient = useQueryClient();

  const { setCurrentGiftCard, setIsAddGiftCardOpen, setIsAddGiftCardPending } =
    useGiftCardsStore().use.actions();

  return function useAddGiftCardSuccessCallback(
    res: GiftCardTypes.Operations.AddGiftCard.TResponse
  ) {
    queryClient.invalidateQueries({
      queryKey: giftCardsQueryKeys.allCardsGiftcards,
    });

    setCurrentGiftCard(res);
    setIsAddGiftCardOpen(false);
    setIsAddGiftCardPending(false);
  };
}
