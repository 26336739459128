import { ButtonSpinner, Divider } from '@/components';

import styles from './edit-card-nickname-footer.module.css';

import type { BankCardsTypes } from '../../types/bank-cards-types';

type TEditCardNicknameFormFooter = {
  updateCardNickname: BankCardsTypes.Operations.UpdateBankCardNickname.TMutation;
};

export function EditCardNicknameFormFooter({
  updateCardNickname,
}: TEditCardNicknameFormFooter) {
  return (
    <div className={styles['edit-card-nickname-footer']}>
      <Divider />

      <ButtonSpinner
        disabled={updateCardNickname.isPending}
        level="primary"
        loading={updateCardNickname.isPending}
        size="medium"
        spinnerColor={'var(--colors-onSurfaceC)'}
        type="submit"
        variant="branded"
      >
        Save
      </ButtonSpinner>
    </div>
  );
}
