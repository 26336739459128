import { GiftCardArt } from '../gift-card-art/gift-card-art';

import styles from './eligible-gift-card-list-item.module.css';

import type { TGetGiftCardProgram } from '../../types/gift-card-component-types';

type TEligibleGiftCardListItem = {
  program: TGetGiftCardProgram;
};
export function EligibleGiftCardListItem({
  program,
}: TEligibleGiftCardListItem) {
  return (
    <li className={styles['container']}>
      <div>
        <GiftCardArt imageUrl={program.display?.logo_url} size={'sm'} />
      </div>
      <div className={styles['program-name']}>
        <strong>{program.display_name}</strong>
      </div>
    </li>
  );
}
