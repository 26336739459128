import { useHasUserConsentedTerms } from '@/features/profile';
import { isCardErrorTnc } from '@/utils';

import { LoadingCard } from '../loading-card';

import { CardsPanelContent } from './cards-panel-content';
import styles from './cards-panel.module.css';

import type { TCardsPanelProps } from './cards-panel-common-types';

export function CardsPanel({
  addCardButton,
  children,
  panelEmpty,
  panelError,
  query,
  title,
}: TCardsPanelProps) {
  const consentedTerms = useHasUserConsentedTerms();

  const isCardsQueryLoading =
    !consentedTerms ||
    isCardErrorTnc(query?.error, query?.isFetching, query?.isError);

  const isHasCards = query.isSuccess && !!query?.data?.cards?.length;

  return (
    <div className={styles['cards-panel']}>
      <div className={styles['cards-panel-header']}>
        <h2 className={styles['cards-panel-title']}>{title}</h2>

        {isHasCards && addCardButton}
      </div>

      {isCardsQueryLoading ? (
        <LoadingCard />
      ) : (
        <CardsPanelContent
          panelEmpty={panelEmpty}
          panelError={panelError}
          query={query}
        >
          {children}
        </CardsPanelContent>
      )}
    </div>
  );
}
