import { useQueryClient } from '@tanstack/react-query';

import { BankCardsDialogErrorStates, useBankCardsStore } from '@/store';

import { paymentMethodQueryKeys } from '../query-keys';

import type { TBaasAddPaymentMethodErrors } from '../../types';
import type { BankCardsTypes } from '../../types/bank-cards-types';
import type { TBaasErrorResponse } from '@/services';

export function useAuthorizeCardError() {
  const queryClient = useQueryClient();
  const bankCards =
    queryClient.getQueryData<BankCardsTypes.Operations.GetBankCards.TResponse>(
      paymentMethodQueryKeys.allCardsBank
    );

  const setBankCardsDialogErrorState =
    useBankCardsStore().use.actions().setBankCardsDialogErrorState;

  return function addCardAuthorizeErrorCallback(
    error: TBaasErrorResponse<TBaasAddPaymentMethodErrors>
  ) {
    // TODO:
    // FLY-30098
    // https://auspayplus.atlassian.net/browse/FLY-3098
    //
    // BaaS service errors appear to be incorrectly mapped,
    // below is a temporary fix to allow for the correct dialog
    // to display in the event an Add Card is done when the user
    // is over the limit threshold.

    // Please use baasServiceErrorsMappings to correctly map the
    // error number to the appropriate error dialog state
    !!error &&
    !!bankCards &&
    bankCards?.cards.length >=
      bankCards?.metadata.user_bank_cards_limit.max_allowed
      ? setBankCardsDialogErrorState(
          BankCardsDialogErrorStates.BANK_CARD_COUNT_LIMIT_REACHED
        )
      : setBankCardsDialogErrorState(
          BankCardsDialogErrorStates.BANK_CARD_ADD_ERROR
        );
  };
}
