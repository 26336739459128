import {
  ERROR_SET_DEFAULT_CARD_ALREADY_DEFAULT,
  ERROR_TITLE_SAVE_CARD,
} from '@/config/language/errors';
import { GiftCardsDialogErrorStates } from '@/store';

import type { ReactNode } from 'react';

type TGiftCardsDialogErrorCopyProps = {
  buttonText?: string;
  description: ReactNode | undefined;
  title: string;
};

type TGiftCardsDialogErrorCopy = Record<
  keyof typeof GiftCardsDialogErrorStates,
  ((walletName?: string) => TGiftCardsDialogErrorCopyProps) | undefined
>;

export const giftCardsDialogErrorCopy: TGiftCardsDialogErrorCopy = {
  [GiftCardsDialogErrorStates.GIFT_CARD_ALREADY_ADDED](walletName?: string) {
    const title = !!walletName
      ? `This card has already been added to your ${walletName} wallet.`
      : 'This card has already been added to your wallet.';

    return {
      description: undefined,
      title,
    };
  },

  [GiftCardsDialogErrorStates.GIFT_CARD_CANCELLED]() {
    return {
      description: undefined,
      title: 'Cannot add gift card. This card has been cancelled.',
    };
  },

  [GiftCardsDialogErrorStates.GIFT_CARD_COUNT_LIMIT_REACHED]() {
    return {
      description: <ERROR_SET_DEFAULT_CARD_ALREADY_DEFAULT />,
      title: ERROR_TITLE_SAVE_CARD,
    };
  },

  [GiftCardsDialogErrorStates.GIFT_CARD_DELETE_ERROR]() {
    return {
      description:
        'Sorry, there was an issue removing your card. Please try again.',
      title: ERROR_TITLE_SAVE_CARD,
    };
  },

  [GiftCardsDialogErrorStates.GIFT_CARD_EXPIRED]() {
    return {
      description: undefined,
      title: 'Cannot add gift card. This card has expired.',
    };
  },

  [GiftCardsDialogErrorStates.GIFT_CARD_INSUFFICIENT_BALANCE]() {
    return {
      description: undefined,
      title: 'Cannot add gift card. This card has a zero balance.',
    };
  },

  [GiftCardsDialogErrorStates.GIFT_CARD_NOT_ACTIVATED_OR_ISSUED]() {
    return {
      description: undefined,
      title: 'Cannot add gift card. Gift card is not activated.',
    };
  },

  [GiftCardsDialogErrorStates.GIFT_CARD_NOT_SUPPORTED](walletName?: string) {
    const description = !!walletName
      ? `This type of gift card is not accepted by ${walletName} so we can't add it to your wallet.`
      : "This type of gift card is not accepted so we can't add it to your wallet.";

    return {
      description,
      title: 'Cannot add gift card. This gift card is not accepted.',
    };
  },

  [GiftCardsDialogErrorStates.GIFT_CARD_SUSPENDED]() {
    return {
      description: undefined,
      title: 'Cannot add gift card. This card has been suspended. ',
    };
  },

  [GiftCardsDialogErrorStates.GIFT_CARD_UNKNOWN_ERROR]() {
    return {
      description: 'Something went wrong. Please try again.',
      title: "Hmm, that didn't work",
    };
  },

  [GiftCardsDialogErrorStates.NULL]: undefined,
};
