import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  ButtonSpinner,
  Checkbox,
  Divider,
  ErrorDialog,
  Form,
} from '@/components';
import { genericContent } from '@/config/language';
import { ERROR_SAVE_ADDRESS_GENERIC } from '@/config/language/errors';
import { useUpdateAddress } from '@/features/profile/services';
import { validate } from '@/utils';

import { AddressInput, AddressInputFieldNames } from '../address-input';

import { Styled } from './styles';

import type { AddressesTypes } from '@/features/profile/';
import type { FieldValues } from 'react-hook-form';

interface IProps {
  address: AddressesTypes.AddressResponse;
  addressId: string;
  isDefault: boolean;
  isEditAddressOpen: boolean;
  onSuccess: () => void;
}

const validationSchema = Joi.object({
  [AddressInputFieldNames.ADDRESS_NICKNAME]: validate.addressNickname(),
  [AddressInputFieldNames.ADDRESS_NAME]: validate.name(),
  [AddressInputFieldNames.ADDRESS_LINE_1]: validate.addressLine1(),
  [AddressInputFieldNames.ADDRESS_LINE_2]: validate.addressLine2(),
  [AddressInputFieldNames.ADDRESS_CITY]: validate.addressCity(),
  [AddressInputFieldNames.ADDRESS_STATE]: validate.addressState(),
  [AddressInputFieldNames.ADDRESS_POSTCODE]: validate.addressPostcode(),
}).unknown(true);

export const EditAddress = ({
  address,
  addressId,
  isDefault,
  isEditAddressOpen,
  onSuccess,
}: IProps) => {
  const defaultValues = useMemo(
    () =>
      ({
        [AddressInputFieldNames.ADDRESS_NICKNAME]: address?.nickname || '',
        [AddressInputFieldNames.ADDRESS_NAME]: address?.recipient_name || '',
        [AddressInputFieldNames.ADDRESS_LINE_1]: address?.address_line_1 || '',
        [AddressInputFieldNames.ADDRESS_LINE_2]: address?.address_line_2 || '',
        [AddressInputFieldNames.ADDRESS_CITY]: address?.suburb || '',
        [AddressInputFieldNames.ADDRESS_STATE]: address?.state || '',
        [AddressInputFieldNames.ADDRESS_POSTCODE]: address?.postcode || '',
        [AddressInputFieldNames.ADDRESS_IS_DEFAULT]: isDefault,
      }) as FieldValues,
    [address, isDefault]
  );

  const formMethods = useForm({
    defaultValues,
    reValidateMode: 'onChange',
    resolver: joiResolver(validationSchema),
  });

  useEffect(() => {
    formMethods.reset(defaultValues);
  }, [defaultValues, formMethods, isEditAddressOpen]);

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const updateAddress = useUpdateAddress({
    onError: () => {
      setIsDialogOpen(true);
    },
    onSuccess,
  });

  const onSubmitForm = async ({
    addressCity,
    addressIsDefault,
    addressLine1,
    addressLine2,
    addressName,
    addressNickname,
    addressPostcode,
    addressState,
  }: FieldValues) => {
    updateAddress.mutate({
      address: {
        address_line_1: addressLine1,
        address_line_2: addressLine2,
        country_code: 'AU',
        is_shipping: addressIsDefault,
        nickname: addressNickname,
        postcode: addressPostcode,
        recipient_name: addressName,
        state: addressState,
        suburb: addressCity,
      },
      addressId: addressId,
    });
  };

  const onCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const isSubmitting = updateAddress.isPending;

  return (
    <Styled.Container>
      <Form config={formMethods} onSubmitForm={onSubmitForm}>
        <div>
          {isEditAddressOpen && <AddressInput isSubmitting={isSubmitting} />}

          <Checkbox
            id={AddressInputFieldNames.ADDRESS_IS_DEFAULT}
            label="Set as default address"
            {...formMethods.register(
              `${AddressInputFieldNames.ADDRESS_IS_DEFAULT}`
            )}
          />
        </div>
        <Styled.Footer>
          <Divider />
          <ButtonSpinner
            disabled={isSubmitting}
            level="primary"
            loading={isSubmitting}
            size="medium"
            spinnerColor={'var(--colors-onSurfaceC)'}
            type="submit"
            variant="branded"
          >
            Save
          </ButtonSpinner>
        </Styled.Footer>
      </Form>
      <ErrorDialog
        isOpen={isDialogOpen}
        message={ERROR_SAVE_ADDRESS_GENERIC}
        onOpenChange={onCloseDialog}
        title={genericContent.ERROR_GENERIC_TITLE}
      />
    </Styled.Container>
  );
};
