import { Buffer } from 'buffer/';

// import type { PaymentMethodTypes } from '../types';
import type { BankCardsTypes } from '../types/bank-cards-types';

// export const generateBankCardCipherText = async ({
//   data,
//   publicKeyDetails,
// }: PaymentMethodTypes.TAddCardRequest): Promise<string> => {

export async function generateBankCardCipherText({
  data,
  encryption,
}: BankCardsTypes.Operations.AuthorizeBankCard.TRequest): Promise<string> {
  const cipherData = {
    cvv: data.cvv,
    expiry_month: data.expiryMonth,
    expiry_year: data.expiryYear,
    number: data.number,
  };

  const importedKey = await window.crypto.subtle.importKey(
    'spki',
    Buffer.from(encryption.public_key, 'base64'),
    {
      hash: 'SHA-256',
      name: 'RSA-OAEP',
    },
    true,
    ['encrypt']
  );

  const cipher = await window.crypto.subtle.encrypt(
    { name: 'RSA-OAEP' },
    importedKey,
    Buffer.from(JSON.stringify(cipherData), 'utf8')
  );

  const cipherText = Buffer.from(cipher).toString('base64');
  return `${cipherText}.${encryption.key_id}`;
}
