import { useEffect } from 'react';

import { Dialog } from '@/components';
import { useAnalytics } from '@/hooks';
import { BankCardsDialogSuccessStates, useBankCardsStore } from '@/store';

import { BankCardsDialogSuccessContent } from './bank-cards-dialog-success-content';
import { bankCardsDialogSuccessCopy } from './bank-cards-dialog-success-copy';

export function BankCardsDialogSuccess() {
  const { track } = useAnalytics();

  const bankCardsDialogSuccessState =
    useBankCardsStore().use.bankCardsDialogSuccessState();

  const setBankCardsDialogSuccessState =
    useBankCardsStore().use.actions().setBankCardsDialogSuccessState;

  const bankCardsDialogSuccessProps =
    bankCardsDialogSuccessCopy?.[bankCardsDialogSuccessState];

  const handleOpenChange = () => {
    setBankCardsDialogSuccessState(BankCardsDialogSuccessStates.NULL);
  };

  useEffect(() => {
    if (
      bankCardsDialogSuccessState === BankCardsDialogSuccessStates.BANK_CARD_ADD
    ) {
      track(`Add Card Confirmed`);
    }
  }, [bankCardsDialogSuccessState, track]);

  const isOpenBankCardsDialogSuccess =
    bankCardsDialogSuccessState !== BankCardsDialogSuccessStates.NULL;

  return (
    <Dialog
      body={
        <BankCardsDialogSuccessContent
          buttonText={bankCardsDialogSuccessProps?.buttonText || 'OK'}
          description={bankCardsDialogSuccessProps?.description}
          handleOpenChange={handleOpenChange}
          title={bankCardsDialogSuccessProps?.title || ''}
        />
      }
      description={bankCardsDialogSuccessProps?.title || ''}
      isOpen={isOpenBankCardsDialogSuccess}
      onOpenChange={handleOpenChange}
    />
  );
}
